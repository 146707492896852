import React, { Component } from 'react';

export default class MonthlyReportViewer extends Component {
    // The componentDidMount() method runs after the component output has been rendered to the DOM. 
    componentDidMount() {
        window.jQuery('#reportViewer1')
            .telerik_ReportViewer({
                serviceUrl: '/api/reports/',
                templateUrl: './assets/telerikReportViewerTemplate-FA-15.0.21.120.html',
                reportSource: {
                    report: 'DailyReport.trdp',
                    parameters: { DataPointsDeviceEui: 'A81758FFFE05825E' }
                },
                scale: 1.0,
                viewMode: 'INTERACTIVE',
                printMode: 'SPECIFIC',
                sendEmail: { enabled: false }
            });
    }

    render() {
        return <div id="reportViewer1"></div>
    }
}